import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  IHttpClient,
  ReportError,
} from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import {
  getOrderPages,
  getOrdersPageById,
  syncOrdersPageStorage,
  syncPagesByOperations,
} from './editor.utils';
import { OperationsClient } from 'root/api/operationClient';
import type { PageData } from '@wix/platform-editor-sdk';
import type { IBIReporterService } from 'root/services/biReporterService';
import { withRetry } from 'root/utils/retryUtils';
import { SPECS } from 'root/appConsts/experiments';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

export const registerComponentChangeEvents = async ({
  editorSDK,
  flowAPI,
  fedopsLogger,
  httpClient,
  pageIdPrefix,
  reportError,
  sentry,
  biLogger,
  msid,
  isStudio,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
  fedopsLogger: FedopsLogger;
  httpClient: IHttpClient;
  pageIdPrefix: string;
  sentry: ErrorMonitor;
  reportError: ReportError;
  biLogger: IBIReporterService;
  msid: string;
  isStudio: boolean;
}) => {
  await editorSDK.addEventListener('componentDeleted', async (event) => {
    const STEP_NAME = 'delete_operation';
    const { componentRef, componentType } = event.detail;
    if (componentType === 'mobile.core.components.Page') {
      fedopsLogger.deleteOLOPageStarted();
      const { ordersPage, numOfPages } = await getOrdersPageById(editorSDK, componentRef.id);
      if (numOfPages === 1) {
        // uninstalling the app, no need to delete the operation
        biLogger.reportOloEditorInstallationStepsEvent({
          step: STEP_NAME,
          value: 'last page - no need to delete operation',
          msid,
        });
        return;
      }

      const getOperationIdByPage = (pageData?: PageData) => {
        return pageData?.tpaPageId?.slice(pageIdPrefix.length + 1);
      };

      const operationId = getOperationIdByPage(ordersPage);
      if (!operationId) {
        sentry
          ? sentry.captureMessage('Failed to get operationId from pageId', {
              contexts: {
                ordersPage: {
                  name: ordersPage?.title,
                  tpaPageId: ordersPage?.tpaPageId,
                },
              },
            })
          : reportError('Failed to get operationId from pageId');
        return;
      }
      const pages = (await getOrderPages(editorSDK)).filter(
        (p) => getOperationIdByPage(p) === operationId
      );
      if (pages.length) {
        biLogger.reportOloEditorInstallationStepsEvent({
          step: STEP_NAME,
          value: `Operation is linked to additional page/s - ${JSON.stringify(pages)}`,
          msid,
        });
        return;
      }

      const operationsClient = new OperationsClient(httpClient, fedopsLogger, sentry, reportError);
      const operations = await operationsClient.getOperations();
      if (operations?.length === 1) {
        biLogger.reportOloEditorInstallationStepsEvent({
          step: STEP_NAME,
          value: 'only one operation exists',
          msid,
        });
        return;
      }
      const isSuccessful = await operationsClient.deleteOperation(operationId);
      biLogger.reportOloEditorPageDeletedBiEvent({
        operationId,
        isSuccessful,
      });
      await syncOrdersPageStorage(editorSDK);
      fedopsLogger.deleteOLOPageEnded();
    }
  });

  await editorSDK.addEventListener('appVisitedInDashboard', async (event) => {
    if (!event.detail.visitedAppDefIds.includes(APP_DEF_IDS.orders)) {
      return;
    }

    const { experiments } = flowAPI;
    const isMultiPages = experiments.enabled(SPECS.multiPages);
    if (isMultiPages) {
      const operations = await withRetry({
        fn: new OperationsClient(httpClient).getOperations,
        args: [],
        name: 'getOperations',
        maxTry: 5,
      });
      await syncPagesByOperations({
        editorSDK,
        flowAPI,
        fedopsLogger,
        biReporterService: biLogger,
        isStudio,
        msid,
        operationList: operations,
      });
    }
  });
};
