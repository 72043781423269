export const KB_LINKS = {
  HEADER_HELP: {
    url: 'https://support.wix.com/en/article/change-your-restaurant-orders-menu-title-and-description',
    id: '199efcc3-a65c-4f6f-aa0d-c5b8d9189ef0',
  },
  HEADER_DESIGN_PANEL: {
    id: 'b60b06a4-199d-4e87-b5be-83c79c6c46f5',
    url: 'https://support.wix.com/en/article/title-and-description-text-design',
  },
  BADGES_HELP: {
    url: 'https://support.wix.com/en/article/restaurant-orders-menu-badge',
    id: '94cdb162-395b-47c0-b909-6c418f02d4f1',
  },
  BADGES_DESIGN_PANEL: {
    id: 'ca4f24c9-e541-4cd1-8d24-299bd2663f6d',
    url: 'https://support.wix.com/en/article/wix-restaurants-orders-orders-menu-badge-design',
  },
  FULFILMENT_INFO_HELP: {
    id: '04cb8274-0342-429c-9c9d-165ca11bf72e',
    url: 'https://support.wix.com/en/article/restaurant-orders-fulfillment-info-design',
  },
  FULFILLMENT_INFO_DESIGN_PANEL: {
    id: '5f1a35c1-bf99-4ab2-900b-3b58bd99d0de',
    url: 'https://support.wix.com/en/article/wix-restaurants-orders-fulfillment-info-design',
  },
  FULFILLMENT_PICKER_DESIGN_PANEL: {
    id: 'bbaa7741-8753-46d9-8046-884173163aaf',
    url: 'https://support.wix.com/en/article/wix-restaurant-orders-fulfillment-picker-design',
  },
  FULFILLMENT_PICKER_HELP: {
    id: 'bbaa7741-8753-46d9-8046-884173163aaf',
    url: 'https://support.wix.com/en/article/wix-restaurant-orders-fulfillment-picker-design',
  },
  NAVIGATION_HELP: {
    id: '418a0205-c93d-4819-881b-c1df71523804',
    url: 'https://support.wix.com/en/article/restaurant-orders-navigation-design',
  },
  NAVIGATION_DESIGN_PANEL: {
    id: '418a0205-c93d-4819-881b-c1df71523804',
    url: 'https://support.wix.com/en/article/wix-restaurants-orders-orders-navigation-design',
  },
  MANAGE_ITEMS_HELP: {
    id: '1b7352e7-ec64-45c2-ac15-11f4ce694a05',
    url: 'https://support.wix.com/en/article/restaurant-orders-manage-items',
  },
  MANAGE_ITEMS_DESIGN_PANEL: {
    id: '18769520-f6a7-41fb-afa1-dbef4e71e829',
    url: 'https://support.wix.com/en/article/restaurant-orders-items-design',
  },
  SECTION_DESCRIPTION_HELP: {
    id: '625aa582-282a-48fc-8bd4-23a44778d70d',
    url: 'https://support.wix.com/en/article/wix-restaurants-text-settings',
  },
  SECTION_TITLE_HELP: {
    id: '625aa582-282a-48fc-8bd4-23a44778d70d',
    url: 'https://support.wix.com/en/article/wix-restaurants-text-settings',
  },
  MENU_TITLE_HELP: {
    id: '625aa582-282a-48fc-8bd4-23a44778d70d',
    url: 'https://support.wix.com/en/article/wix-restaurants-text-settings',
  },
  OLO_WIDGET_DESIGN_PANEL: {
    id: '90547178-47cf-4123-91e7-31e35bd58568',
  },
  CART_BUTTON_HELP: {
    id: 'a7e44855-2e2a-4f0f-b69b-fdb2e618d55a',
  },
  CART_BUTTON_DESIGN_PANEL: {
    id: 'a7e44855-2e2a-4f0f-b69b-fdb2e618d55a',
  },
};
