import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';

export const getWidgetProps = async <T = WidgetProps>(
  editorSDK: EditorSDK | null,
  componentRef: ComponentRef
) => {
  const widgetProps = await editorSDK?.document.application.appStudioWidgets.props.get('', {
    widgetRef: componentRef,
  });
  return widgetProps as T;
};

export const setWidgetProps = async <T extends WidgetProps>(
  editorSDK: EditorSDK | null,
  componentRef: ComponentRef,
  newProps: T
) => {
  await editorSDK?.document.application.appStudioWidgets.props.set('', {
    widgetRef: componentRef,
    newProps,
  });
};
