import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { EditorSDK, PageData, PageRef } from '@wix/platform-editor-sdk';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { IBIReporterService } from 'root/services/biReporterService';
import {
  getMissingPopups,
  installAppIfMissing,
  isPageInstalledByAppDefId,
  progressBarMoveToStepNumber,
} from './editor.utils';
import { LIGHTBOX_IDS, PAGE_DATA } from 'root/appConsts/consts';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { createAppPage } from 'root/utils/createPage';
import { installLightBoxByTpaAppId, installLightboxes } from './oloLightboxesInstallation';
import type { EcomComponentConfiguration } from '@wix/ecom-platform-sdk';
import { SPECS } from 'root/appConsts/experiments';

export async function handleFirstInstall({
  editorSDK,
  t,
  experiments,
  appDefId,
  msid,
  isResponsive,
  isStudio,
  fedopsLogger,
  biReporterService,
  subjectType,
  shouldOpenProgressBar,
  isOptimusOrigin = false,
}: {
  editorSDK: EditorSDK;
  t: TFunction;
  experiments: Experiments;
  appDefId: string;
  msid: string;
  isResponsive: boolean;
  isStudio: boolean;
  fedopsLogger: FedopsLogger;
  biReporterService: IBIReporterService;
  subjectType: string;
  shouldOpenProgressBar: boolean;
  isOptimusOrigin?: boolean;
}) {
  // eslint-disable-next-line no-console
  console.log('Online orders - installation starting...');

  const commonBIReq = {
    msid,
    isStudio,
    isFirstInstall: true,
    isInstallationRetry: false,
  };

  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'first_install_started',
    ...commonBIReq,
  });

  await progressBarMoveToStepNumber(editorSDK, t, 1, shouldOpenProgressBar);

  const oloPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: PAGE_DATA,
    isResponsive,
    isStudio,
    shouldAddMenuItem: true,
    hidePage: isOptimusOrigin,
    fedopsLogger,
    t,
  });

  const { menusPromise, tipsPromise, trackerPromise } = installAppsIfMissing({
    editorSDK,
    fedopsLogger,
    biReporterService,
    isFirstInstall: true,
    experiments,
    isOptimusOrigin,
  });

  biReporterService?.reportOloGenericDebugBiEvent({
    subjectType,
    value: {
      editorReady: 'before_installing_lightboxes',
      oloPageRef,
      ...commonBIReq,
    },
  });
  // eslint-disable-next-line no-console
  console.log('Online orders - installing lightboxes...');
  const isFirstInstall = true;
  const lightboxesPromise = installLightboxes(
    editorSDK,
    t,
    appDefId,
    isResponsive,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );

  // eslint-disable-next-line no-console
  console.log('Online orders - first install ended.');
  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'first_install_ended',
    ...commonBIReq,
  });
  return { oloPageRef, menusPromise, lightboxesPromise, tipsPromise, trackerPromise };
}

export const handleInstallationRetry = async ({
  editorSDK,
  t,
  experiments,
  appDefId,
  msid,
  isResponsive,
  isStudio,
  biReporterService,
  fedopsLogger,
}: {
  editorSDK: EditorSDK;
  t: TFunction;
  experiments: Experiments;
  appDefId: string;
  msid: string;
  isResponsive: boolean;
  isStudio: boolean;
  biReporterService: IBIReporterService;
  fedopsLogger: FedopsLogger;
}) => {
  const isOLOPageInstalled = await isPageInstalledByAppDefId(editorSDK, APP_DEF_IDS.orders);
  const commonBIReq = {
    msid,
    isResponsive,
    isStudio,
  };

  if (!isOLOPageInstalled) {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'OLO_page_not_exists_retry',
      appDefId,
      ...commonBIReq,
    });
    await createAppPage({
      editorSDK,
      appDefId,
      pageData: PAGE_DATA,
      isResponsive,
      isStudio,
      shouldAddMenuItem: true,
      fedopsLogger,
      t,
    });
  }

  const { tipsPromise, trackerPromise } = installAppsIfMissing({
    editorSDK,
    fedopsLogger,
    biReporterService,
    isFirstInstall: false,
    experiments,
  });

  await Promise.all([tipsPromise, trackerPromise]);

  const lightboxIds = [LIGHTBOX_IDS.itemModal, LIGHTBOX_IDS.dispatchModal, LIGHTBOX_IDS.errorModal];

  const missingPopups = await getMissingPopups(editorSDK, lightboxIds);

  missingPopups.forEach(async (popup) => {
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: `popup_is_not_installed`,
      value: popup,
      ...commonBIReq,
    });
    const isFirstInstall = false;
    await installLightBoxByTpaAppId[popup](
      editorSDK,
      t,
      appDefId,
      isResponsive,
      biReporterService,
      fedopsLogger,
      isFirstInstall
    );
  });
};

export const NEW_TIPS_APP_DEF_ID = '869a50c8-dd38-4324-b201-e33dc0747a5f';
const installAppsIfMissing = ({
  editorSDK,
  fedopsLogger,
  biReporterService,
  isFirstInstall,
  experiments,
  isOptimusOrigin,
}: {
  editorSDK: EditorSDK;
  fedopsLogger: FedopsLogger;
  biReporterService: IBIReporterService;
  isFirstInstall: boolean;
  experiments: Experiments;
  isOptimusOrigin?: boolean;
}) => {
  const menusPromise =
    isFirstInstall &&
    installAppIfMissing(
      editorSDK,
      APP_DEF_IDS.menus,
      biReporterService,
      fedopsLogger,
      isFirstInstall,
      isOptimusOrigin
    );
  const tipsPromise = installAppIfMissing(
    editorSDK,
    experiments.enabled(SPECS.installNewTips) ? NEW_TIPS_APP_DEF_ID : APP_DEF_IDS.tips,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );
  const trackerPromise = installAppIfMissing(
    editorSDK,
    APP_DEF_IDS.orderTracker,
    biReporterService,
    fedopsLogger,
    isFirstInstall
  );

  return { menusPromise, tipsPromise, trackerPromise };
};

export const duplicatePage = async (editorSDK: EditorSDK, sourcePageId: string) => {
  return editorSDK.pages.duplicate('_token', {
    pageId: sourcePageId,
    shouldAddMenuItem: true,
    shouldDuplicatePageCode: true,
  }) as unknown as PageRef;
};

export const updatePage = async (
  editorSDK: EditorSDK,
  pageRef: PageRef,
  operationId?: string,
  title?: string,
  pageUriSEO?: string
) => {
  if (operationId) {
    if (pageRef && pageRef.id) {
      const data: Partial<PageData> = {
        tpaPageId: `${PAGE_DATA.pageId}-${operationId}`,
      };

      if (title) {
        data.title = title;
      }
      if (pageUriSEO) {
        data.pageUriSEO = pageUriSEO;
      }
      await editorSDK.pages.data.update('token', {
        pageRef,
        data,
      });
    }
  }
};

export const ecomComponentsConfiguration = {
  requireEcomComponents: async (): Promise<EcomComponentConfiguration> => {
    return {
      ecomComponents: {},
    };
  },
};
