import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { configureWidgetTextDesignGfpp, getRole } from '../../utils/manifestUtils';
import type { FlowEditorSDK, EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { SECTION_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { KB_LINKS } from 'root/appConsts/kb-links';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.set({
    displayName: 'Widget Section',
    nickname: 'SectionWidget',
  });

  configureWidgetTextDesignGfpp({
    widgetBuilder,
    t,
    textComponentIds: [
      {
        componentId: SECTION_WIDGET_COMPONENT_IDS.sectionTitle,
        helpId: KB_LINKS.SECTION_TITLE_HELP.id,
      },
      {
        componentId: SECTION_WIDGET_COMPONENT_IDS.sectionDescription,
        helpId: KB_LINKS.SECTION_DESCRIPTION_HELP.id,
      },
    ],
  });

  widgetBuilder.configureConnectedComponents(
    getRole(SECTION_WIDGET_COMPONENT_IDS.dishesContainer),
    (componentBuilder) => {
      componentBuilder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false } });
    }
  );
};
