import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { configureWidgetTextDesignGfpp } from '../../utils/manifestUtils';
import type { FlowEditorSDK, EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import { MENU_WIDGET_COMPONENT_IDS } from '../../appConsts/blocksIds';
import { KB_LINKS } from 'root/appConsts/kb-links';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.set({
    displayName: 'Widget Menu',
    nickname: 'MenuWidget',
  });

  configureWidgetTextDesignGfpp({
    widgetBuilder,
    t,
    textComponentIds: [
      { componentId: MENU_WIDGET_COMPONENT_IDS.menuTitle, helpId: KB_LINKS.MENU_TITLE_HELP.id },
      {
        componentId: MENU_WIDGET_COMPONENT_IDS.menuDescription,
      },
    ],
  });
};
