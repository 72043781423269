import type {
  Operation as OperationAPI,
  TimeUnit,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import {
  SchedulingType,
  FulfillmentType,
  AsapFutureHandlingType,
} from '@wix/ambassador-restaurants-operations-v1-operation/types';
import type { AsapScheduling, Operation, PreorderScheduling } from '../../types/businessTypes';
import { DispatchType } from '../../types/businessTypes';

const TIME_UNIT_MULTIPLIER: Record<TimeUnit, number> = {
  MINUTES: 1,
  UNKNOWN_TIME_UNIT: 0,
  DAYS: 24 * 60,
  HOURS: 60,
};
export const OperationAdapter = (operation: OperationAPI): Operation => {
  const { asapFutureHandlingType, businessDaysAheadHandlingOptions } =
    operation.orderScheduling?.asapOptions || {};
  const { daysCount } = businessDaysAheadHandlingOptions || {};
  const allowAsapFutureHandling =
    asapFutureHandlingType === AsapFutureHandlingType.BUSINESS_DAYS_AHEAD_HANDLING &&
    Number(daysCount) >= 0;

  return {
    id: operation.id || '',
    name: operation.name || '',
    enabled: !!operation.enabled,
    fulfillmentIds: operation.fulfillmentIds || [],
    asapOptions: getAsapOptionsFromOperation(operation),
    preOrderOptions: getPreOrderOptions(operation),
    operationType: getOperationType(operation),
    allowAsapFutureHandling,
    businessDaysAheadHandlingOptions: daysCount ?? undefined,
    defaultDispatchType:
      operation.defaultFulfillmentType === FulfillmentType.DELIVERY
        ? DispatchType.DELIVERY
        : DispatchType.PICKUP,
  };
};

function getOperationType(operation: OperationAPI) {
  switch (operation.orderScheduling?.type) {
    case SchedulingType.PREORDER:
      return 'PRE_ORDER';
    case SchedulingType.ASAP:
    default:
      return 'ASAP';
  }
}

function getPreOrderOptions(operation: OperationAPI): PreorderScheduling | undefined {
  if (!operation.orderScheduling?.preorderOptions) {
    return;
  }
  const timeBoundedOptions = operation.orderScheduling?.preorderOptions?.method?.timeBoundedOptions;

  if (!timeBoundedOptions) {
    return;
  }
  const max =
    TIME_UNIT_MULTIPLIER[timeBoundedOptions!.maximumInAdvanceTime!.timeUnit!] *
    timeBoundedOptions!.maximumInAdvanceTime!.duration!;
  const min =
    TIME_UNIT_MULTIPLIER[timeBoundedOptions!.minimumInAdvanceTime!.timeUnit!] *
    timeBoundedOptions!.minimumInAdvanceTime!.duration!;
  const timeWindowsOptions =
    operation.orderScheduling?.preorderOptions?.fulfillmentTimesDisplayConfig?.timeWindowsOptions;
  const timeWindowDuration =
    TIME_UNIT_MULTIPLIER[timeWindowsOptions!.timeUnit!] * timeWindowsOptions!.duration!;
  return {
    timeInAdvance: { min, max },
    timeWindowDuration,
  };
}

function getAsapOptionsFromOperation(operation: OperationAPI): AsapScheduling | undefined {
  if (!operation.orderScheduling?.asapOptions) {
    return;
  }
  const { timeRangeOptions, maxTimeOptions } =
    operation.orderScheduling.asapOptions.preparationTime || {};
  if (
    maxTimeOptions &&
    maxTimeOptions.duration !== null &&
    maxTimeOptions.duration !== undefined &&
    maxTimeOptions.timeUnit
  ) {
    return {
      maxInMinutes: maxTimeOptions.duration * TIME_UNIT_MULTIPLIER[maxTimeOptions.timeUnit],
    };
  }
  if (
    timeRangeOptions &&
    timeRangeOptions.timeUnit &&
    timeRangeOptions.rangeMaximumDuration !== undefined &&
    timeRangeOptions.rangeMaximumDuration !== null &&
    timeRangeOptions.rangeMinimumDuration !== undefined &&
    timeRangeOptions.rangeMinimumDuration !== null
  ) {
    return {
      rangeInMinutes: {
        min:
          timeRangeOptions.rangeMinimumDuration * TIME_UNIT_MULTIPLIER[timeRangeOptions.timeUnit],
        max:
          timeRangeOptions.rangeMaximumDuration * TIME_UNIT_MULTIPLIER[timeRangeOptions.timeUnit],
      },
    };
  }
}
