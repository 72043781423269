import {
  getDishesElementsPanelData,
  getHeaderElementsPanelData,
  getItemModalElementsPanelData,
  getOloElementsPanelData,
} from './elementsData';
import type {
  ComponentRef,
  ElementCategoryData,
  ElementData,
  RemoveComponentHandler,
  AddComponentHandler,
} from '@wix/platform-editor-sdk';
import type { TFunction, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { ROLES_BINDING, ROLES_DEPENDENCIES } from '../../appConsts/consts';

const TOKEN = 'token';

export const openElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  categoriesData: ElementCategoryData[],
  elementsData: ElementData[]
) => {
  const getRoleByCompRef = async (componentRef: ComponentRef) => {
    const connection = await editorSDK.controllers.listConnections(TOKEN, {
      componentRef,
    });
    if (connection?.length) {
      return connection[0].role;
    }
  };

  const getCompByRoles = async (
    roles: string[]
  ): Promise<{ componentRef: ComponentRef; role?: string }[]> => {
    const connections = await editorSDK.components.getChildren(TOKEN, {
      componentRef: widgetRef,
      fromDocument: true,
      recursive: true,
    });
    const componentList = (
      await Promise.all(
        connections.map(async (componentRef) => {
          return {
            componentRef,
            role: await getRoleByCompRef(componentRef),
          };
        })
      )
    ).filter((ref) => {
      return roles.includes(ref.role as string);
    });

    return componentList;
  };

  const showComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent(TOKEN, {
      componentRef,
    });
  };

  const hideComp = async (componentRef: ComponentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent(TOKEN, {
      componentRef,
    });
  };

  const isCompHidden = async (componentRef: ComponentRef) => {
    return editorSDK.components.refComponents.isRefComponentCollapsed(TOKEN, {
      componentRef,
    });
  };

  const onShowComp: AddComponentHandler = async ({ role }) => {
    const roles = [role];
    const roleBind = ROLES_BINDING[role as keyof typeof ROLES_BINDING];
    if (roleBind) {
      roles.push(roleBind);
    }

    for (const dep of ROLES_DEPENDENCIES) {
      if ((dep.source as unknown as string).includes(role)) {
        roles.push(dep.target);
        break;
      }
    }

    const componentsToShow = await getCompByRoles(roles);

    await Promise.all(componentsToShow.map(({ componentRef }) => showComp(componentRef)));
  };

  const onHideComp: RemoveComponentHandler = async (compRef: ComponentRef, _elementIdentifier) => {
    const role = await getRoleByCompRef(compRef).then((r) => r);
    const roleBind = ROLES_BINDING[_elementIdentifier.role as keyof typeof ROLES_BINDING];
    const roles = [role];

    if (roleBind) {
      roles.push(roleBind);
    }

    for (const dep of ROLES_DEPENDENCIES) {
      if (role && (dep.source as unknown as string).includes(role)) {
        let allDepRolesAreHidden = true;
        for (const sourceRole of dep.source) {
          if (role === sourceRole) {
            continue;
          }
          const [sourceRef] = await getCompByRoles([sourceRole]);
          if (!(await isCompHidden(sourceRef.componentRef))) {
            allDepRolesAreHidden = false;
            break;
          }
        }
        if (allDepRolesAreHidden) {
          roles.push(dep.target);
        }
      }
    }

    const componentsToHide = await getCompByRoles(roles as string[]);

    componentsToHide.forEach(({ componentRef }) => {
      hideComp(componentRef);
    });
  };

  await editorSDK.editor.openElementsPanel(TOKEN, {
    widgetRef,
    categoriesData,
    elementsData,
    addComponentHandler: onShowComp,
    removeComponentHandler: onHideComp,
  });

  return { onShowComp, onHideComp };
};

export const openOloElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  enabledExperiments: { isFloatingCartButtonEnabled: boolean }
) => {
  const { categoriesData, elementsData } = getOloElementsPanelData(t, enabledExperiments);

  await openElementsPanel(editorSDK, widgetRef, categoriesData, elementsData);
};

export const openHeaderElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  enabledExperiments: { isFloatingCartButtonEnabled: boolean }
) => {
  const { categoriesData, elementsData } = getHeaderElementsPanelData(t, enabledExperiments);

  await openElementsPanel(editorSDK, widgetRef, categoriesData, elementsData);
};

export const openDishesElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  enabledExperiments: { isFloatingCartButtonEnabled: boolean }
) => {
  const { categoriesData, elementsData } = getDishesElementsPanelData(t, enabledExperiments);

  await openElementsPanel(editorSDK, widgetRef, categoriesData, elementsData);
};

export const openItemModalElementsPanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  t: TFunction,
  enabledExperiments: { isFloatingCartButtonEnabled: boolean }
) => {
  const { categoriesData, elementsData } = getItemModalElementsPanelData(t, enabledExperiments);

  await openElementsPanel(editorSDK, widgetRef, categoriesData, elementsData);
};
