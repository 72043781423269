import type { TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { CART_BUTTON_COMPONENT_IDS, NAVIGATION_TOOLBAR_IDS } from 'root/appConsts/blocksIds';
import { NAVIGATION_WIDGET_DESIGN_PANEL_TABS } from 'root/appConsts/designPanelTabs';
import { SPECS } from 'root/appConsts/experiments';
import { KB_LINKS } from 'root/appConsts/kb-links';
import { configureWidgetDesign, disableElementsSelection } from 'root/editor/editor.utils';
import { getRole } from 'root/utils/utils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  widgetBuilder.set({
    displayName: 'Navigation',
    nickname: 'NavigationWidget',
  });

  widgetBuilder.behavior().set({ removable: false, preventHide: true });

  const {
    experiments,
    translations: { t },
  } = flowAPI;
  const isFloatingCartButtonEnabled =
    experiments.enabled(SPECS.floatingCartButton) ||
    experiments.enabled(SPECS.floatingCartButtonEditor);

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('design-gfpp.navigation.design'),
      actionType: 'design',
      actionId: '',
    })
    .set('design', {
      behavior: 'DEFAULT',
    })
    .set('help', { id: KB_LINKS.NAVIGATION_HELP.id });

  widgetBuilder.configureConnectedComponents(
    getRole(NAVIGATION_TOOLBAR_IDS.box),
    (componentBuilder) => {
      componentBuilder.behavior().set({ preventHide: true });
    }
  );

  const tabs = NAVIGATION_WIDGET_DESIGN_PANEL_TABS;
  if (isFloatingCartButtonEnabled && tabs[2].label !== 'design-panel.tabs.navigation.cartButton') {
    tabs.splice(2, 0, {
      label: 'design-panel.tabs.navigation.cartButton',
      roles: [CART_BUTTON_COMPONENT_IDS.button],
    });
  }

  configureWidgetDesign({
    widgetBuilder,
    helpId: KB_LINKS.NAVIGATION_DESIGN_PANEL.id,
    title: t('design-panel.navigation.title'),
    tabs,
    t: t as TFunction,
  });

  const DISABLED_ELEMENTS = [
    NAVIGATION_TOOLBAR_IDS.menuDropdown,
    NAVIGATION_TOOLBAR_IDS.sectionTabs,
    NAVIGATION_TOOLBAR_IDS.navigationDivider,
    NAVIGATION_TOOLBAR_IDS.cartButton,
  ];

  disableElementsSelection(widgetBuilder, DISABLED_ELEMENTS);
};
