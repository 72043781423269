import { MA_APP_IDS } from '@wix/members-area-integration-kit';

export const OLO_APP_NAME = 'Wix Restaurants Orders (New)';

const APP_PRESETS = {
  desktop: 'variants-lhyxpyib2',
  mobile: 'variants-lhz0ea8v',
};

export const DISH_ITEM_PRESETS = {
  desktop: {
    layout: 'variants-lhyxqi763',
    style: 'variants-lhyxqi763',
  },
  mobile: {
    layout: 'variants-liiydpgg',
    style: 'variants-liiydpgg',
  },
};

export const DISPATCH_MODAL_PRESETS = {
  desktop: {
    layout: 'variants-li0co6yq3',
    style: 'variants-li0co6yq3',
  },
  mobile: {
    layout: 'variants-lj1514tc',
    style: 'variants-lj1514tc',
  },
};

export const ERROR_MODAL_PRESETS = {
  desktop: {
    layout: 'variants-ljcx0uqt1',
    style: 'variants-ljcx0uqt1',
  },
  mobile: {
    layout: 'variants-ljzll927',
    style: 'variants-ljzll927',
  },
};

export const PAGE_DATA = {
  pageId: 'menu-orders-page',
  title: 'app.page.title',
  widgetId: '5077609c-e47c-4da6-8147-008d243e4499',
  desktopPresetId: APP_PRESETS.desktop,
  mobilePresetId: APP_PRESETS.mobile,
  pageUriSEO: 'online-ordering',
};

export const PANEL_IDS = {
  oloElementsPanel: 'openShowHidePanel',
  headerSettings: 'headerSettings',
  headerLayout: 'headerLayout',
  imageLayout: 'imageLayout',
  headerElements: 'headerElements',
  itemLayout: 'itemLayout',
  manageMenus: 'manageMenus',
  ordersSettings: 'ordersSettings',
  dishesElementsPanel: 'dishesElementsPanel',
  itemModalElements: 'itemModalElements',
  menuSettings: 'menuSettings',
  cartButtonSettings: 'cartButtonSettings',
};
export const PANEL_NAMES = {
  headerSettings: 'headerSettings',
  headerLayout: 'headerLayout',
  headerLayoutMobile: 'headerLayoutMobile',
  imageLayout: 'imageLayout',
  itemLayout: 'Layout',
  itemLayoutMobile: 'Layout Mobile',
};

export const HEADER_ELEMENTS_IDS = {
  headerImage: 'headerImage',
  headerTitle: 'headerTitle',
  headerDescription: 'headerDescription',
  navigation: 'navigation',
  cartButton: 'cartButton',
};

export const MENUS_ELEMENTS_IDS = {
  menuTitle: 'menuTitle',
  menuDescription: 'menuDescription',
  sectionTitle: 'sectionTitle',
  sectionDescription: 'sectionDescription',
  menuDivider: 'menuDivider',
};

export const DISHES_ELEMENTS_IDS = {
  itemImage: 'itemImage',
  itemDescription: 'itemDescription',
  labelContainer: 'labelContainer',
};

export const ITEM_MODAL_ELEMENTS_IDS = {
  image: 'proGallery',
  subTitle: 'subTitle',
  labelsContainer: 'labelsContainer',
};

export const ROLES_BINDING = {
  itemImage: 'outOfStockItemImage',
  itemDescription: 'outOfStockItemDescription',
  labelContainer: 'outOfStockLabelsContainer',
} as const;

export const ROLES_DEPENDENCIES = [
  { source: ['headerTitle', 'headerDescription'], target: 'titleAndDescriptionWrapper' },
];

export const LIGHTBOX_IDS = {
  dispatchModal: 'dispatchModal',
  itemModal: 'itemModal',
  errorModal: 'errorModal',
};

export const ITEM_MODAL_APP_DEF_ID = 'fead24ed-3548-4e05-82f1-dbb76debe567';
export const DISPATCH_MODAL_APP_DEF_ID = '8ad8f1b5-b637-449a-9ec2-7dc704a3782c';
export const ERROR_MODAL_APP_DEF_ID = '7bb9e6da-b8e1-41f9-8120-1a5d71908a28';

export const ERROR_MODAL_WIDTH = 500;
export const MAX_CHARS_SECTION_TAB = 20;

export const OLO_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-ups3j';
export const HEADER_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-h7lco';
export const MENU_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-lj4wo';
export const SECTION_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-vwep7';
export const DISHES_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-txgvp';
export const NAVIGATION_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-umijd';
export const CART_BUTTON_CONTROLLER_WIDGET_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60-izs55';

export const CART_BUTTON_WIDGET_ID = '4003cca6-5e9b-439a-8cc2-8d708d1d2ce6';

export const HELP_IDS = {
  OLO: '6fbc098b-1d68-4096-9f91-fbf23ca65a61',
  ITEM_MODAL: 'ad7b561b-a9bb-42fa-accc-478fa59dd9cd',
  DISPATCH_MODAL: 'ab9007ad-e960-4c1f-b0ef-7c356f943fc0',
};

export const OLO_DASHBOARD_URLS = {
  settings: '/olo-settings',
  viewOrders: '/restaurants-orders-prep-board',
};

export const BM_OPERATION_QUERY_PARAM = '?operationId=';

export const MEMBERS_AREA_DEFAULT_APPS = [
  MA_APP_IDS.MY_ORDERS,
  MA_APP_IDS.MY_ADDRESSES,
  MA_APP_IDS.MY_WALLET,
];

export const DEFAULT_ADDRESS = '500 Terry Francine Street, San Francisco, CA 94158, USA';

export const LIGHTBOX_QUERY_PARAM_KEY = 'lightboxOnScreen';

export const APP_MARKET_PANEL = 'tpa.compPanels.appMarketPanel';

export const LIGHTBOX_CONNECTED_PARAMS = {
  CONTROLLER: 'popupController',
  ROLE: 'popupContainer',
};

export const ExperimentScope = 'restaurants';

export const OPTIMUS_ORIGIN_TYPE = 'OPTIMUS';
