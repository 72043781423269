import type { TFunction, WidgetBuilder } from '@wix/yoshi-flow-editor';

export const getRole = (componentId: string) => {
  return componentId.substring(1);
};

const EDIT_TEXT_BUTTON = 'mainAction2';

const configureTextMainActionSettingsGfpp = ({
  widgetBuilder,
  actionId,
  t,
  role,
  helpId,
}: {
  widgetBuilder: WidgetBuilder;
  actionId?: string;
  t: TFunction;
  role: string;
  helpId?: string;
}) => {
  const mainGfppParams = actionId
    ? { label: t('app.gfpp.common.editText'), actionId }
    : { behavior: 'HIDE' };
  widgetBuilder.configureConnectedComponents(role, (componentBuilder) => {
    // @ts-expect-error
    componentBuilder.gfpp().set(EDIT_TEXT_BUTTON, mainGfppParams);
    if (helpId) {
      componentBuilder.gfpp().set('help', { id: helpId });
    }
    componentBuilder.behavior().set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
  });
};

export const configureWidgetTextDesignGfpp = ({
  widgetBuilder,
  actionId,
  t,
  textComponentIds,
}: {
  widgetBuilder: WidgetBuilder;
  actionId?: string;
  t: TFunction;
  textComponentIds: { componentId: string; helpId?: string }[];
}) => {
  for (const textComponentId of textComponentIds) {
    configureTextMainActionSettingsGfpp({
      widgetBuilder,
      actionId,
      t,
      role: getRole(textComponentId.componentId),
      helpId: textComponentId.helpId,
    });
  }
};
