export const SPECS = {
  editCartItem: 'specs.restaurants.editCartItem',
  skipMemberAreaAutoInstall: 'specs.restaurants.skipMemberAreaAutoInstall',
  menuOrderPanel: 'specs.restaurants.menuOrderPanel',
  myBusinessActions: 'specs.restaurants.myBusinessActions',
  multiPages: 'specs.restaurants.oloMultiPages',
  getMenusByOperation: 'specs.restaurants.getMenusByOperation',
  openItemModalByQueryParams: 'specs.restaurants.openItemModalByQueryParams',
  floatingCartButton: 'specs.restaurants.oloCartButtonBob',
  floatingCartButtonEditor: 'specs.restaurants.oloCartButtonEditor',
  menuAvailability: 'specs.restaurants.menuAvailabilityLivesite',
  cartButtonSettings: 'specs.restaurants.oloCartButtonSettings',
  truncateMenus: 'specs.restaurants.useTruncateMenus',
  truncateToValue: 'specs.restaurants.truncateMenusToValue',
  truncateFromValue: 'specs.restaurants.truncateMenusFromValue',
  unchangedDeliveryAddress: 'specs.restaurants.unchangedDeliveryAddress',
  seo: 'specs.restaurants.oloSeo',
  menuStatus: 'specs.restaurants.menuStatus-livesite',
  installNewTips: 'specs.restaurants.installNewTips',
} as const;
